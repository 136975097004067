import { LcsItemProps } from '@/components/sections/euro-5/lcs/lcs-item';
import { IMenu } from '@/types/layout/menu';
import { IButton } from '@/types/shared/button';

// CV SECONDARY NAV
export const MENU_CV_SECONDARY_NAV_LCV: IMenu = {
  label: 'รถปิกอัพและรถอเนกประสงค์',
  url: 'https://www.isuzu-tis.com',
  target: '_blank',
};

export const MENU_CV_SECONDARY_NAV_CV: IMenu = {
  label: 'รถบรรทุก',
  url: '/',
  active: true,
};

export const MENU_CV_SECONDARY_NAV: IMenu[] = [
  MENU_CV_SECONDARY_NAV_LCV,
  MENU_CV_SECONDARY_NAV_CV,
];

// CV BUYING TOOLS
export const MENU_CV_REGISTER: IMenu = {
  label: 'สนใจ<span>รถบรรทุก</span>',
  description: 'ลงทะเบียนรับข้อเสนอพิเศษ',
  url: '/register',
  icon: 'truck',
};

export const MENU_CV_COMPARE: IMenu = {
  label: 'ค้นหาและ<span>เปรียบเทียบ</span>',
  description: 'เปรียบเทียบรายละเอียดทางเทคนิค',
  url: '/product',
  icon: 'compare',
};

export const MENU_CV_DEALER: IMenu = {
  label: 'ค้นหา<span>โชว์รูม</span> / <span>ศูนย์บริการ</span>',
  description: 'ค้นหาโชว์รูมอีซูซุใกล้บ้านคุณได้ที่นี่',
  url: '/dealer',
  icon: 'place',
};

export const MENU_CV_DOWNLOAD_BROCHURE: IMenu = {
  label: 'ดาวน์โหลด<span>โบรชัวร์</span>',
  description:
    'รายละเอียดข้อมูลทางเทคนิค<span>ของรถ</span><span>ทุกรุ่น</span>',
  url: '/download-brochure',
  icon: 'map',
};

export const MENU_CV_SELL_YOUR_TRUCK: IMenu = {
  label: 'ขาย<span>รถมือสอง</span>',
  description: 'ลงทะเบียนเพื่อประเมินราคารถ',
  url: '/register/sell-your-truck',
  icon: 'sell-your-truck',
};

export const MENU_CV_BUYING_TOOLS: IMenu[] = [
  MENU_CV_REGISTER,
  MENU_CV_COMPARE,
  MENU_CV_DEALER,
  MENU_CV_DOWNLOAD_BROCHURE,
  MENU_CV_SELL_YOUR_TRUCK,
];

// CV MODEL
export const MENU_CV_MODEL_LABEL = `รุ่นรถบรรทุก`;

export const MENU_GXZ_360_THE_ROCK: IMenu = {
  url: '/product/euro-3/gxz',
  label: 'GXZ 360/THE ROCK',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte34120d73bb2a768/63101406f86a7b0f54595d6e/gxz-360-2022.png',
    alt: 'GXZ 360/THE ROCK',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt83a24ef0a49e9612/649bae90b93cad11cea701fb/spec-sheet-all-gxz-2023.pdf',
  features: [
    {
      detail: '360 แรงม้า<br />345 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM<br />1,324 N-m ที่ 1,300 - 1,700 RPM',
    },
    {
      detail: 'GCW 50.5 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'G',
    },
    {
      char: 'X',
    },
    {
      char: 'Z',
    },
  ],
};

// NEW
export const MENU_GXZ_360: IMenu = {
  url: '/product/euro-3/gxz',
  label: 'GXZ 360',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte34120d73bb2a768/63101406f86a7b0f54595d6e/gxz-360-2022.png',
    alt: 'GXZ 360',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt83a24ef0a49e9612/649bae90b93cad11cea701fb/spec-sheet-all-gxz-2023.pdf',
  features: [
    {
      detail: '360 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM',
    },
    {
      detail: 'GCW 50.5 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'G',
    },
    {
      char: 'X',
    },
    {
      char: 'Z',
    },
  ],
};

// NEW
export const MENU_GXZ_THE_ROCK: IMenu = {
  url: '/product/euro-3/gxz',
  label: 'GXZ THE ROCK',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3070a56a0d1aae10/65f92205f4a4cf541714f602/euro-3-gxz-the-rock.png',
    alt: 'GXZ THE ROCK',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt83a24ef0a49e9612/649bae90b93cad11cea701fb/spec-sheet-all-gxz-2023.pdf',
  features: [
    {
      detail: '345 แรงม้า',
    },
    {
      detail: '1,324 N-m ที่ 1,300 - 1,700 RPM',
    },
    {
      detail: 'GCW 50.5 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'G',
    },
    {
      char: 'X',
    },
    {
      char: 'Z',
    },
  ],
};

export const MENU_FTR_TRACTOR: IMenu = {
  url: '/product/euro-3/ftr-tractor',
  label: 'FTR TRACTOR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt25407b8609583c22/61f124b25874d72782979f77/ftr-center.png',
    alt: 'FTR TRACTOR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2bbf5c6f59c2c8cb/649baebefcb6fd4de55ac500/spec-sheet-isuzu-ftr-tractor-2023.pdf',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GCW 35 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'T',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_FYH_360: IMenu = {
  url: '/product/euro-3/fyh',
  label: 'FYH 360',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt813c0b809fc79ba5/627e20683b9b85549049a0f6/FYH.png',
    alt: 'FYH 360',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8649eba0635df282/649baebf1ea8295786f57adb/spec-sheet-isuzu-fyh-2023.pdf',
  features: [
    {
      detail: '360 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM',
    },
    {
      detail: 'GVW 30 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'Y',
    },
    {
      char: 'H',
    },
  ],
};

export const MENU_FXZ_360_THE_ROCK: IMenu = {
  url: '/product/euro-3/fxz',
  label: 'FXZ 360/THE ROCK',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt562c0d77ac9009ad/627e214d045749535846c44d/FXZ.png',
    alt: 'FXZ 360/THE ROCK',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0cbf6c318136682b/649bae9113bff894812ed154/spec-sheet-all-fxz-2023.pdf',
  features: [
    {
      detail: '360 แรงม้า<br />345 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM<br />1,324 N-m ที่ 1,300 - 1,700 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'X',
    },
    {
      char: 'Z',
    },
  ],
};

// NEW
export const MENU_FXZ_360: IMenu = {
  url: '/product/euro-3/fxz',
  label: 'FXZ 360',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt562c0d77ac9009ad/627e214d045749535846c44d/FXZ.png',
    alt: 'FXZ 360',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0cbf6c318136682b/649bae9113bff894812ed154/spec-sheet-all-fxz-2023.pdf',
  features: [
    {
      detail: '360 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'X',
    },
    {
      char: 'Z',
    },
  ],
};

// NEW
export const MENU_FXZ_THE_ROCK: IMenu = {
  url: '/product/euro-3/fxz',
  label: 'FXZ THE ROCK',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt35e1348461ded9e7/65f92205f50f9a0a267c162f/euro-3-fxz-the-rock.png',
    alt: 'FXZ THE ROCK',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0cbf6c318136682b/649bae9113bff894812ed154/spec-sheet-all-fxz-2023.pdf',
  features: [
    {
      detail: '345 แรงม้า',
    },
    {
      detail: '1,324 N-m ที่ 1,300 - 1,700 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'X',
    },
    {
      char: 'Z',
    },
  ],
};

export const MENU_FVZ_300_240: IMenu = {
  url: '/product/euro-3/fvz',
  label: 'FVZ 300/240',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdce87fa2787ddfa9/61f12504a4c49a2752822e29/fvz-300-240-center.png',
    alt: 'FVZ 300/240',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf7c4023a6f3e03b5/649baebff7bfd1eadb8326af/spec-sheet-isuzu-fvz-2023.pdf',
  features: [
    {
      detail: '300 แรงม้า<br />240 แรงม้า',
    },
    {
      detail: '980 N-m ที่ 1,450 RPM<br />706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'V',
    },
    {
      char: 'Z',
    },
  ],
};

// NEW
export const MENU_FVZ_300: IMenu = {
  url: '/product/euro-3/fvz',
  label: 'FVZ 300',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf114003ca9fb7c3c/62467dba71dd7f0f223e8eb9/fvz-300-240-center.png',
    alt: 'FVZ 300',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf7c4023a6f3e03b5/649baebff7bfd1eadb8326af/spec-sheet-isuzu-fvz-2023.pdf',
  features: [
    {
      detail: '300 แรงม้า',
    },
    {
      detail: '980 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'V',
    },
    {
      char: 'Z',
    },
  ],
};

// NEW
export const MENU_FVZ_240: IMenu = {
  url: '/product/euro-3/fvz',
  label: 'FVZ 240',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdce87fa2787ddfa9/61f12504a4c49a2752822e29/fvz-300-240-center.png',
    alt: 'FVZ 240',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf7c4023a6f3e03b5/649baebff7bfd1eadb8326af/spec-sheet-isuzu-fvz-2023.pdf',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'V',
    },
    {
      char: 'Z',
    },
  ],
};

export const MENU_FVM_300_240: IMenu = {
  url: '/product/euro-3/fvm',
  label: 'FVM 300/240',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9ce295c0050d33e3/61f124f2031a18274b668742/fvm-300-240-center.png',
    alt: 'FVM 300/240',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1288c697b7c032a5/649bb451fcb6fd62cb5ac504/spec-sheet-all-fvm-2023.pdf',
  features: [
    {
      detail: '300 แรงม้า<br />240 แรงม้า',
    },
    {
      detail: '980 N-m ที่ 1,450 RPM<br />706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า<br />6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'V',
    },
    {
      char: 'M',
    },
  ],
};

// NEW
export const MENU_FVM_300: IMenu = {
  url: '/product/euro-3/fvm',
  label: 'FVM 300',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9ce295c0050d33e3/61f124f2031a18274b668742/fvm-300-240-center.png',
    alt: 'FVM 300',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1288c697b7c032a5/649bb451fcb6fd62cb5ac504/spec-sheet-all-fvm-2023.pdf',
  features: [
    {
      detail: '300 แรงม้า',
    },
    {
      detail: '980 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'V',
    },
    {
      char: 'M',
    },
  ],
};

// NEW
export const MENU_FVM_240: IMenu = {
  url: '/product/euro-3/fvm',
  label: 'FVM 240',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9ce295c0050d33e3/61f124f2031a18274b668742/fvm-300-240-center.png',
    alt: 'FVM 240',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1288c697b7c032a5/649bb451fcb6fd62cb5ac504/spec-sheet-all-fvm-2023.pdf',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],

  watermark: [
    {
      char: 'F',
    },
    {
      char: 'V',
    },
    {
      char: 'M',
    },
  ],
};

export const MENU_FTR_240: IMenu = {
  url: '/product/euro-3/ftr',
  label: 'FTR 240',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta21a4f90697f6b64/61f1248f5874d72782979f75/ftr-240-center.png',
    alt: 'FTR 240',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdbf50297ae443527/6511173bbc13843b6d7d372f/spec-sheet-ftr-2023.pdf',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 15 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'T',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_FRR_210_190: IMenu = {
  url: '/product/euro-3/frr',
  label: 'FRR 210/190',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt530ee3aee43e5a79/61f123973a862229877318d8/frr-210-190-center.png',
    alt: 'FRR 210/190',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta96e156c6070132a/65111ccda5f15daf19fc6099/spec-sheet-all-frr-2023.pdf',
  features: [
    {
      detail: '210 แรงม้า<br />190 แรงม้า',
    },
    {
      detail: '637 N-m ที่ 1,600 RPM<br />510 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 9.9 - 10.4 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'R',
    },
    {
      char: 'R',
    },
  ],
};

// NEW
export const MENU_FRR_210: IMenu = {
  url: '/product/euro-3/frr',
  label: 'FRR 210',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt530ee3aee43e5a79/61f123973a862229877318d8/frr-210-190-center.png',
    alt: 'FRR 210',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta96e156c6070132a/65111ccda5f15daf19fc6099/spec-sheet-all-frr-2023.pdf',
  features: [
    {
      detail: '210 แรงม้า',
    },
    {
      detail: '637 N-m ที่ 1,600 RPM',
    },
    {
      detail: 'GVW 9.9 - 10.4 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'R',
    },
    {
      char: 'R',
    },
  ],
};

// NEW
export const MENU_FRR_190: IMenu = {
  url: '/product/euro-3/frr',
  label: 'FRR 190',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt530ee3aee43e5a79/61f123973a862229877318d8/frr-210-190-center.png',
    alt: 'FRR 190',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta96e156c6070132a/65111ccda5f15daf19fc6099/spec-sheet-all-frr-2023.pdf',
  features: [
    {
      detail: '190 แรงม้า',
    },
    {
      detail: '510 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 9.9 - 10.4 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'R',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_NQR_175: IMenu = {
  url: '/product/euro-3/nqr',
  label: 'NQR 175',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbe527a12d3c60b47/62afebea682a22504d4e752a/NQR_175_revise.png',
    alt: 'NQR 175',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0020f1d8a5c9fe73/649baed0f7bfd1cddf8326b3/spec-sheet-isuzu-nqr-2023.pdf',
  features: [
    {
      detail: '175 แรงม้า',
    },
    {
      detail: '500 N-m ที่ 1,500 - 2,000 RPM',
    },
    {
      detail: 'GVW 9.5 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'Q',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_NPR_150: IMenu = {
  url: '/product/euro-3/npr',
  label: 'NPR 150',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf4aea09b80464548/62afebcc92725d5bf8180c16/NPR_150_revise.png',
    alt: 'NPR 150',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltec7949a5e6ddc547/65111bc6a5f15d159dfc6092/spec-sheet-npr-2023.pdf',
  features: [
    {
      detail: '150 แรงม้า',
    },
    {
      detail: '404 N-m ที่ 1,500 - 2,600 RPM',
    },
    {
      detail: 'GVW 8.5 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'P',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_NMR_130: IMenu = {
  url: '/product/euro-3/nmr',
  label: 'NMR 130',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6e6edd6003f16658/62afeb8b05f1d157f3a8c1fd/NMR_130_revise.png',
    alt: 'NMR 130',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt33595b636cd4c660/651117c64e27a098fa4f5bbb/spec-sheet-nmr-2023.pdf',
  features: [
    {
      detail: '130 แรงม้า',
    },
    {
      detail: '330 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 6.5 - 7.4 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'M',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_NLR_130_LITE: IMenu = {
  url: '/product/euro-3/nlr',
  label: 'NLR 130/Lite',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3161e5590abc1d9e/62a2b190b53bb658a3925253/NLR_130.png',
    alt: 'NLR 130/Lite',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f19ee181f459b5c/649bb452bcdab0098e3befe8/spec-sheet-all-nlr-2023.pdf',
  features: [
    {
      detail: '130 แรงม้า<br />104 แรงม้า',
    },
    {
      detail:
        '330 N-m ที่ 1,600 - 2,600 RPM<br />230 N-m ที่ 1,400 - 3,200 RPM',
    },
    {
      detail: 'GVW 4.4 ตัน<br />GVW 4.2 ตัน',
    },
    {
      detail: '5 เกียร์เดินหน้า<br />6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'L',
    },
    {
      char: 'R',
    },
  ],
};

// NEW
export const MENU_NLR_130: IMenu = {
  url: '/product/euro-3/nlr',
  label: 'NLR 130',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3161e5590abc1d9e/62a2b190b53bb658a3925253/NLR_130.png',
    alt: 'NLR 130',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f19ee181f459b5c/649bb452bcdab0098e3befe8/spec-sheet-all-nlr-2023.pdf',
  features: [
    {
      detail: '130 แรงม้า',
    },
    {
      detail: '330 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 4.4 ตัน',
    },
    {
      detail: '5 และ 6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'L',
    },
    {
      char: 'R',
    },
  ],
};

// NEW
export const MENU_NLR_LITE: IMenu = {
  url: '/product/euro-3/nlr',
  label: 'NLR Lite',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcf3df59bc8d25ee6/65e95558c9226cb4efe85e46/euro-3-nlr-lite.png',
    alt: 'NLR Lite',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f19ee181f459b5c/649bb452bcdab0098e3befe8/spec-sheet-all-nlr-2023.pdf',
  features: [
    {
      detail: '104 แรงม้า',
    },
    {
      detail: '230 N-m ที่ 1,400 - 3,200 RPM',
    },
    {
      detail: 'GVW 4.2 ตัน',
    },
    {
      detail: '5 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'L',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_FXZ_MIXER_320: IMenu = {
  url: '/product/euro-3/fxz',
  label: 'FXZ Mixer',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltac75874634418977/631014067cb46356cd3a8daa/fxz-320-2022-mixer.png',
    alt: 'FXZ Mixer',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt456128b35d07e942/649baec06c102e6530a5698b/spec-sheet-isuzu-fxz-320-mixer-2023.pdf',
  features: [
    {
      detail: '320 แรงม้า',
    },
    {
      detail: '1,255 N-m ที่ 1,250 - 1,700 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: '9 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'X',
    },
    {
      char: 'Z',
    },
  ],
};

export const MENU_FRR_MIXER_190: IMenu = {
  url: '/product/euro-3/frr',
  label: 'FRR Mixer',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8e99106c4295d705/61f12470b870e6280280078b/frr-mixer-190-center.png',
    alt: 'FRR Mixer',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt107ac5f55cad7828/649baebd1fa6aa8c1dadf737/spec-sheet-isuzu-frr-mixer-2023.pdf',
  features: [
    {
      detail: '190 แรงม้า',
    },
    {
      detail: '510 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 9.9 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'R',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_NMR_MIXER_130: IMenu = {
  url: '/product/euro-3/nmr',
  label: 'NMR Mixer',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb6fbe408440c25fc/62a2b2a734f22956975fa7be/ELF_MIXER.png',
    alt: 'NMR Mixer',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltad0059710332ba62/649baed0efa20d82384bd580/spec-sheet-isuzu-nmr-mixer-2023.pdf',
  features: [
    {
      detail: '130 แรงม้า',
    },
    {
      detail: '330 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 8 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'M',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_NPR_MINI_BUS: IMenu = {
  url: '/product/euro-3/npr-mini-bus',
  label: 'NPR มินิบัส แชสซีส์',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9fb132d474a95d08/61f125cb12244c25ceb6df5b/npr-mini-bus-chassis.png',
    alt: 'NPR มินิบัส แชสซีส์',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2f410f9d1f521f60/649baecfb702423142cabe64/spec-sheet-isuzu-npr-minibus-2023.pdf',
  features: [
    {
      detail: '150 แรงม้า',
    },
    {
      detail: '404 N-m ที่ 1,500 - 2,600 RPM',
    },
    {
      detail: 'GVW 6.5 - 7.5 ตัน',
    },
    {
      detail: '6 เกียร์เดินหน้า',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'P',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_EURO_3_HEADER: IMenu[] = [
  {
    url: '',
    label: 'หัวลาก',
    submenu: [
      {
        url: '',
        label: 'หัวลาก 10 ล้อ',
        submenu: [MENU_GXZ_360_THE_ROCK],
      },
      {
        url: '',
        label: 'หัวลาก 6 ล้อ',
        submenu: [MENU_FTR_TRACTOR],
      },
    ],
  },
  {
    url: '',
    label: '12 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_FYH_360],
      },
    ],
  },
  {
    url: '',
    label: '10 ล้อ',
    submenu: [
      {
        url: '',
        label: 'สองเพลา',
        submenu: [MENU_FXZ_360_THE_ROCK, MENU_FVZ_300_240],
      },
      {
        url: '',
        label: 'เพลาเดียว',
        submenu: [MENU_FVM_300_240],
      },
    ],
  },
  {
    url: '',
    label: '6 ล้อ',
    submenu: [
      {
        url: '',
        label: 'ขนาดใหญ่',
        submenu: [MENU_FTR_240],
      },
      {
        url: '',
        label: 'ขนาดกลาง',
        submenu: [MENU_FRR_210_190, MENU_NQR_175, MENU_NPR_150, MENU_NMR_130],
      },
    ],
  },
  {
    url: '',
    label: '4 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_NLR_130_LITE],
      },
    ],
  },
  {
    url: '',
    label: 'ผสมปูน',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_FXZ_MIXER_320],
      },
      {
        url: '',
        label: '',
        submenu: [MENU_FRR_MIXER_190],
      },
      {
        url: '',
        label: '',
        submenu: [MENU_NMR_MIXER_130],
      },
    ],
  },
];

export const MENU_EURO_3_LINE_UP: IMenu[] = [
  {
    url: '',
    label: 'หัวลาก',
    submenu: [
      {
        url: '',
        label: 'หัวลาก 10 ล้อ',
        submenu: [MENU_GXZ_360, MENU_GXZ_THE_ROCK],
      },
      {
        url: '',
        label: 'หัวลาก 6 ล้อ',
        submenu: [MENU_FTR_TRACTOR],
      },
    ],
  },
  {
    url: '',
    label: '12 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_FYH_360],
      },
    ],
  },
  {
    url: '',
    label: '10 ล้อ',
    submenu: [
      {
        url: '',
        label: 'สองเพลา',
        submenu: [MENU_FXZ_360, MENU_FXZ_THE_ROCK, MENU_FVZ_300, MENU_FVZ_240],
      },
      {
        url: '',
        label: 'เพลาเดียว',
        submenu: [MENU_FVM_300, MENU_FVM_240],
      },
    ],
  },
  {
    url: '',
    label: '6 ล้อ',
    submenu: [
      {
        url: '',
        label: 'ขนาดใหญ่',
        submenu: [MENU_FTR_240],
      },
      {
        url: '',
        label: 'ขนาดกลาง',
        submenu: [
          MENU_FRR_210,
          MENU_FRR_190,
          MENU_NQR_175,
          MENU_NPR_150,
          MENU_NMR_130,
        ],
      },
    ],
  },
  {
    url: '',
    label: '4 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_NLR_130, MENU_NLR_LITE],
      },
    ],
  },
  {
    url: '',
    label: 'ผสมปูน',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_FXZ_MIXER_320],
      },
      {
        url: '',
        label: '',
        submenu: [MENU_FRR_MIXER_190],
      },
      {
        url: '',
        label: '',
        submenu: [MENU_NMR_MIXER_130],
      },
    ],
  },
];

// MENU EURO 5
export const MENU_EURO_5_GXZ: IMenu = {
  url: '/product/gxz',
  label: 'GXZ',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltced435a6ddc38624/65e9948a89f1df1ccc0e7ac8/euro-5-gxz.png',
    alt: 'GXZ',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt142ee3f801f85e67/665989fb9518af283d667bab/gxz-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '349 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM',
    },
    {
      detail: 'GCW 50.5 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_FTR_TRACTOR: IMenu = {
  url: '/product/ftr-tractor',
  label: 'FTR TRACTOR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfd45ba711889aac2/65e9939377faff23695a6348/euro-5-ftr-tractor.png',
    alt: 'FTR TRACTOR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blteb0c6df596d09bfb/ftr-tractor-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GCW 35 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_FYH: IMenu = {
  url: '/product/fyh',
  label: 'FYH',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt409b93c20c86f1ae/65e99459a8b011da8d5c7baf/euro-5-fyh.png',
    alt: 'FYH',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt038c81472d549f8d/fyh-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '349 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM', //cvdf
    },
    {
      detail: 'GVW 30 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_FXZ: IMenu = {
  url: '/product/fxz',
  label: 'FXZ',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltba9f8af053e942ff/65e99441504e03b14df1c1f8/euro-5-fxz.png',
    alt: 'FXZ',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt378fe996a67a2743/fxz-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '349 แรงม้า',
    },
    {
      detail: '1,422 N-m ที่ 1,400 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_FVZ: IMenu = {
  url: '/product/fvz',
  label: 'FVZ',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd3339d1c258bec9b/65e99429c61144e26f92502d/euro-5-fvz.png',
    alt: 'FVZ',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbb1651f5fee7b93e/fvz-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

// NEW
export const MENU_EURO_5_FVZ_S: IMenu = {
  url: '/product/fvz',
  label: 'FVZ-S',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt505777f00190c72a/65e99412ed37f64cb3b10f1a/euro-5-fvz-s.png',
    alt: 'FVZ-S',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbb1651f5fee7b93e/fvz-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '280 แรงม้า',
    },
    {
      detail: '820 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_FVM: IMenu = {
  url: '/product/fvm',
  label: 'FVM',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9116583d3df46231/65e993e7ed466167357887f7/euro-5-fvm.png',
    alt: 'FVM',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcde1a0025e393f65/66729879b00d3911dae01faf/fvm-series.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

// NEW
export const MENU_EURO_5_FVM_S: IMenu = {
  url: '/product/fvm',
  label: 'FVM-S',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9ca2d04c599a6495/65e993d0504e031deaf1c1f4/euro-5-fvm-s.png',
    alt: 'FVM-S',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcde1a0025e393f65/66729879b00d3911dae01faf/fvm-series.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '280 แรงม้า',
    },
    {
      detail: '820 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 25 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_FTR: IMenu = {
  url: '/product/ftr',
  label: 'FTR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt410f1ec3584e83c7/65e9937b7d810a1e8941a742/euro-5-ftr.png',
    alt: 'FTR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd03cb063c3331b78/ftr-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '240 แรงม้า',
    },
    {
      detail: '706 N-m ที่ 1,450 RPM',
    },
    {
      detail: 'GVW 15 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_FRR: IMenu = {
  url: '/product/frr',
  label: 'FRR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte2f270525c19d4b7/65e9934c63ec4202625d8e8d/euro-5-frr.png',
    alt: 'FRR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfcd51859e1b2fbc4/frr-series.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '190 แรงม้า',
    },
    {
      detail: '513 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 9.9 - 10.4 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

// NEW
export const MENU_EURO_5_FRR_S: IMenu = {
  url: '/product/frr',
  label: 'FRR-S',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt20f888d82202ca9d/65e993187756f65aaff6404a/euro-5-frr-s.png',
    alt: 'FRR-S',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5e9906bc6a42b6a0/frr-s-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '203 แรงม้า',
    },
    {
      detail: '637 N-m ที่ 1,600 RPM',
    },
    {
      detail: 'GVW 9.9 - 10.4 ตัน',
    },
    {
      detail: 'DPD',
    },
  ],
};

export const MENU_EURO_5_NQR: IMenu = {
  url: '/product/nqr',
  label: 'NQR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt87314fe2d2718ec0/65e9955d63ec427c785d8e9e/euro-5-nqr.png',
    alt: 'NQR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4747dba62f7fddf6/nqr-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '154 แรงม้า',
    },
    {
      detail: '419 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 9.5 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_NPR: IMenu = {
  url: '/product/npr',
  label: 'NPR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt672619903733e682/65e9951203e460c5c1dc3e6c/euro-5-npr.png',
    alt: 'NPR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0cb26e430f17981f/npr-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '154 แรงม้า',
    },
    {
      detail: '419 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 8.5 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
};

export const MENU_EURO_5_NMR: IMenu = {
  url: '/product/nmr',
  label: 'NMR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4704902cc654f1ad/65e994d1ed46613357788802/euro-5-nmr.png',
    alt: 'NMR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt55328d062164f56c/nmr-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '123 แรงม้า',
    },
    {
      detail: '354 N-m ที่ 1,500 RPM',
    },
    {
      detail: 'GVW 6.5 - 7.4 ตัน',
    },
    {
      detail: 'DOC with POC',
    },
  ],
};

export const MENU_EURO_5_NLR: IMenu = {
  url: '/product/nlr',
  label: 'NLR',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbb19b36db3558045/65e994a26f950cf1eb8c739e/euro-5-nlr.png',
    alt: 'NLR',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6758a6be8426a00c/nlr-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '123 แรงม้า',
    },
    {
      detail: '354 N-m ที่ 1,500 RPM',
    },
    {
      detail: 'GVW 4.4 ตัน',
    },
    {
      detail: 'DOC with POC',
    },
  ],
};

export const MENU_EURO_5_FRR_MIXER: IMenu = {
  url: '/product/frr',
  label: 'FRR Mixer',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blteb5bacb77897b4ab/65e992830395e480622852de/euro-5-frr-mixer.png',
    alt: 'FRR Mixer',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbd8e0d78ee5ca785/frr-mixer-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '190 แรงม้า',
    },
    {
      detail: '513 N-m ที่ 1,600 - 2,600 RPM',
    },
    {
      detail: 'GVW 9.9 ตัน',
    },
    {
      detail: 'DOC',
    },
  ],
  watermark: [
    {
      char: 'F',
    },
    {
      char: 'R',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_EURO_5_NMR_MIXER: IMenu = {
  url: '/product/nmr',
  label: 'NMR Mixer',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte615cb7c431794de/65e994ba6f950c4ab98c73a0/euro-5-nmr-mixer.png',
    alt: 'NMR Mixer',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd68067371af24f25/nmr-mixer-brochure-2024.pdf',
  engineType: 'euro-5',
  features: [
    {
      detail: '123 แรงม้า',
    },
    {
      detail: '354 N-m ที่ 1,500 RPM',
    },
    {
      detail: 'GVW 8 ตัน',
    },
    {
      detail: 'DOC with POC',
    },
  ],
  watermark: [
    {
      char: 'N',
    },
    {
      char: 'M',
    },
    {
      char: 'R',
    },
  ],
};

export const MENU_EURO_5_HEADER: IMenu[] = [
  {
    url: '',
    label: 'หัวลาก',
    submenu: [
      {
        url: '',
        label: 'หัวลาก 10 ล้อ',
        submenu: [MENU_EURO_5_GXZ],
      },
      {
        url: '',
        label: 'หัวลาก 6 ล้อ',
        submenu: [MENU_EURO_5_FTR_TRACTOR],
      },
    ],
  },
  {
    url: '',
    label: '12 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_FYH],
      },
    ],
  },
  {
    url: '',
    label: '10 ล้อ',
    submenu: [
      {
        url: '',
        label: 'สองเพลา',
        submenu: [MENU_EURO_5_FXZ, MENU_EURO_5_FVZ],
      },
      {
        url: '',
        label: 'เพลาเดียว',
        submenu: [MENU_EURO_5_FVM],
      },
    ],
  },
  {
    url: '',
    label: '6 ล้อ',
    submenu: [
      {
        url: '',
        label: 'ขนาดใหญ่',
        submenu: [MENU_EURO_5_FTR],
      },
      {
        url: '',
        label: 'ขนาดกลาง',
        submenu: [
          MENU_EURO_5_FRR,
          MENU_EURO_5_NQR,
          MENU_EURO_5_NPR,
          MENU_EURO_5_NMR,
        ],
      },
    ],
  },
  {
    url: '',
    label: '4 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_NLR],
      },
    ],
  },
  {
    url: '',
    label: 'ผสมปูน',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_FRR_MIXER],
      },
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_NMR_MIXER],
      },
    ],
  },
];

export const MENU_EURO_5_LINEU_UP: IMenu[] = [
  {
    url: '',
    label: 'หัวลาก',
    submenu: [
      {
        url: '',
        label: 'หัวลาก 10 ล้อ',
        submenu: [MENU_EURO_5_GXZ],
      },
      {
        url: '',
        label: 'หัวลาก 6 ล้อ',
        submenu: [MENU_EURO_5_FTR_TRACTOR],
      },
    ],
  },
  {
    url: '',
    label: '12 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_FYH],
      },
    ],
  },
  {
    url: '',
    label: '10 ล้อ',
    submenu: [
      {
        url: '',
        label: 'สองเพลา',
        submenu: [MENU_EURO_5_FXZ, MENU_EURO_5_FVZ, MENU_EURO_5_FVZ_S],
      },
      {
        url: '',
        label: 'เพลาเดียว',
        submenu: [MENU_EURO_5_FVM, MENU_EURO_5_FVM_S],
      },
    ],
  },
  {
    url: '',
    label: '6 ล้อ',
    submenu: [
      {
        url: '',
        label: 'ขนาดใหญ่',
        submenu: [MENU_EURO_5_FTR],
      },
      {
        url: '',
        label: 'ขนาดกลาง',
        submenu: [
          MENU_EURO_5_FRR,
          MENU_EURO_5_FRR_S,
          MENU_EURO_5_NQR,
          MENU_EURO_5_NPR,
          MENU_EURO_5_NMR,
        ],
      },
    ],
  },
  {
    url: '',
    label: '4 ล้อ',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_NLR],
      },
    ],
  },
  {
    url: '',
    label: 'ผสมปูน',
    submenu: [
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_FRR_MIXER],
      },
      {
        url: '',
        label: '',
        submenu: [MENU_EURO_5_NMR_MIXER],
      },
    ],
  },
];

// MENU MODEL HEADER
export const MENU_CV_MODEL_HEADER: IMenu = {
  label: MENU_CV_MODEL_LABEL,
  url: '',
  layout: 1,
  submenu: MENU_EURO_5_HEADER,
  submenu2: MENU_EURO_3_HEADER,
};

// MENU MODEL FOOTER
export const MENU_CV_MODEL_FOOTER: IMenu = {
  label: MENU_CV_MODEL_LABEL,
  url: '',
  submenu: [
    MENU_EURO_5_GXZ,
    MENU_EURO_5_FTR_TRACTOR,
    MENU_EURO_5_FYH,
    MENU_EURO_5_FXZ,
    MENU_EURO_5_FVZ,
    MENU_EURO_5_FVM,
    MENU_EURO_5_FTR,
    MENU_EURO_5_FRR,
    MENU_EURO_5_NQR,
    MENU_EURO_5_NPR,
    MENU_EURO_5_NMR,
    MENU_EURO_5_NLR,
  ],
};

// SPECIAL OFFER
export const MENU_CV_SPECIAL_OFFER: IMenu = {
  url: '/specialoffer',
  label: 'ข้อเสนอพิเศษ',
};

export const MENU_CV_SPECIAL_OFFER_TRUCK: IMenu = {
  url: '/specialoffer/truck-campaign',
  label: 'รถบรรทุก',
};

export const MENU_CV_SPECIAL_OFFER_SERVICE: IMenu = {
  url: '/specialoffer/service-campaign',
  label: 'บริการหลังกายขาย',
};

// CONTENT HUB
export const MENU_CV_CONTENT_HUB = {
  url: '/content-hub',
};

export const MENU_CV_CONTENT_HUB_GALLERY: IMenu = {
  label: 'แกลเลอรี่',
  url: '/content-hub/inspiration',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6407ecaa8af2f9b6/667926412cbc090f2d3cb2c2/menu-content-hub-inspiration.jpg',
    alt: 'แกลเลอรี่',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_CONTENT_HUB_EXPERIENCES: IMenu = {
  label: 'เรื่องราวจากผู้ใช้อีซูซุ',
  url: '/content-hub/experiences',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbec5e23abc990963/630c464c44cff61b783b0410/testimonials.jpg',
    alt: 'เรื่องราวจากผู้ใช้อีซูซุ',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_CONTENT_HUB_EXPERT: IMenu = {
  label: 'เรื่องน่ารู้จากอีซูซุ',
  url: '/content-hub/expert',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4321bdbb3d6a0a8b/667925cecae0aa0dbb52fc33/menu-content-hub-expert.jpg',
    alt: 'เรื่องน่ารู้จากอีซูซุ',
    width: 1600,
    height: 1200,
  },
};

let contentHub = [
  MENU_CV_CONTENT_HUB_EXPERIENCES,
  MENU_CV_CONTENT_HUB_EXPERT,
  MENU_CV_CONTENT_HUB_GALLERY,
];

// if (!ONLY_DEV()) {
contentHub = [MENU_CV_CONTENT_HUB_EXPERT, MENU_CV_CONTENT_HUB_GALLERY];
// }

export const MENU_CV_CONTENT_HUB_GROUP: IMenu = {
  label: 'แชร์สิ่งดีๆ',
  url: '',
  submenu: contentHub,
};

// CV PRIVILEGES
export const MENU_CV_PRIVILEGES_MY_ISUZU: IMenu = {
  label: 'my-ISUZU',
  url: '/privileges/my-isuzu',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt46ac45abc02c1a50/655c253281b93e805da184b1/privileges-1.jpg',
    alt: 'My isuzu',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_PRIVILEGES_EXCELLENCY_CLUB: IMenu = {
  label: 'สมาชิก “อีซูซุเอลฟ์ ยิ่งเข้า ยิ่งคุ้ม”',
  url: '/privileges/ying-kao-ying-koom-elf',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt24bc2da12667d8e4/65e87625c9226ce9efe85939/ying-kao-ying-koom.jpg',
    alt: 'isuzu ying kao ying koom',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_PRIVILEGES: IMenu = {
  label: 'สิทธิประโยชน์',
  url: '',
  submenu: [MENU_CV_PRIVILEGES_MY_ISUZU, MENU_CV_PRIVILEGES_EXCELLENCY_CLUB],
};

// SERVICE
export const MENU_CV_SERVICE_MAINTENANCE_LIST: IMenu = {
  label: 'รายการบำรุงรักษาตามระยะ',
  url: '/service/maintenance-list',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2c797abe77dd9c71/61f2110aa556c527898146e5/2-maintenanceList.jpg',
    alt: 'maintenance list',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_SERVICE_LCV: IMenu = {
  label: 'บริการรถปิกอัพและอเนกประสงค์',
  url: 'https://www.isuzu-tis.com/service/service-center',
  target: '_blank',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4406660efc573bd5/65e92c80a8b0115de45c7a2d/lcv-service.jpg',
    alt: 'Service',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_SERVICE: IMenu = {
  label: 'บริการและสิทธิประโยชน์',
  url: '',
  submenu: [
    MENU_CV_PRIVILEGES_MY_ISUZU,
    MENU_CV_PRIVILEGES_EXCELLENCY_CLUB,
    MENU_CV_SERVICE_MAINTENANCE_LIST,
    MENU_CV_SERVICE_LCV,
  ],
};

// CV ABOUT
export const MENU_CV_ABOUT_CONTACT: IMenu = {
  label: 'ติดต่อเรา',
  url: 'https://www.isuzu-tis.com/contact-us',
  target: '_blank',
};

export const MENU_CV_ABOUT_FAQS: IMenu = {
  label: 'FAQs',
  url: 'https://www.isuzu-tis.com/faqs',
  target: '_blank',
};

export const MENU_CV_ABOUT_HISTORY: IMenu = {
  label: 'ประวัติอีซูซุ',
  url: 'https://www.isuzu-tis.com/about-history',
  target: '_blank',
};

export const MENU_CV_ABOUT_NEWS: IMenu = {
  label: 'ข่าวสารและกิจกรรม',
  url: 'https://www.isuzu-tis.com/news-activities',
  target: '_blank',
};

export const MENU_CV_ABOUT_CAREER: IMenu = {
  label: 'สมัครงาน',
  url: 'https://www.isuzu-tis.com/career',
  target: '_blank',
};

export const MENU_CV_ABOUT_TRI_PETCH_GROUP: IMenu = {
  label: 'กลุ่มตรีเพชร',
  url: 'https://www.tripetchgroup.com',
  target: '_blank',
};

export const MENU_CV_ABOUT_PRIVACY_POLICY: IMenu = {
  label: 'นโยบายความเป็นส่วนตัว',
  url: '/privacy-policy',
};

const MENU_CV_ABOUT_GROUP: IMenu[] = [
  MENU_CV_ABOUT_CONTACT,
  MENU_CV_ABOUT_FAQS,
  MENU_CV_ABOUT_HISTORY,
  MENU_CV_ABOUT_NEWS,
  MENU_CV_ABOUT_CAREER,
  MENU_CV_ABOUT_TRI_PETCH_GROUP,
  MENU_CV_ABOUT_PRIVACY_POLICY,
];

export const MENU_CV_ABOUT: IMenu = {
  label: 'เกี่ยวกับอีซูซุ',
  url: '',
  submenu: MENU_CV_ABOUT_GROUP,
  showpanel: true,
};

// SOCIAL
export const MENU_CV_SOCIAL_FACEBOOK: IMenu = {
  label: 'ISUZU Truck Fanpage',
  url: 'https://www.facebook.com/IsuzuTrucksThailandOfficial',
  target: '_blank',
  icon: 'facebook-circle',
};

export const MENU_CV_SOCIAL_LINE: IMenu = {
  label: 'Line Official',
  url: 'https://line.me/R/ti/p/%40isuzuthai',
  target: '_blank',
  icon: 'line-circle',
};

export const MENU_CV_SOCIAL_YOUTUBE: IMenu = {
  label: 'Youtube Channel',
  url: 'https://www.youtube.com/channel/UCmTP28q4oZsMCyx16PiPJwA',
  target: '_blank',
  icon: 'youtube-circle',
};

export const MENU_CV_SOCIAL_INSTAGRAM: IMenu = {
  label: 'ISUZU TRUCKS Thailand',
  url: 'https://www.instagram.com/isuzutrucksthailand',
  target: '_blank',
  icon: 'instagram-circle',
};

const MENU_CV_SOCIAL_GROUP: IMenu[] = [
  MENU_CV_SOCIAL_FACEBOOK,
  MENU_CV_SOCIAL_LINE,
  MENU_CV_SOCIAL_YOUTUBE,
  MENU_CV_SOCIAL_INSTAGRAM,
];

export const MENU_CV_SOCIAL: IMenu = {
  label: 'ช่องทางติดต่อออนไลน์',
  url: '',
  submenu: MENU_CV_SOCIAL_GROUP,
  showpanel: true,
};

// CONTACT
export const MENU_CV_TRI_PETCH_GROUP: IMenu = {
  label: '',
  url: 'https://www.tripetchgroup.com',
  target: '_blank',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta0ce0ab3e39a763f/61dd8fa825d68c388a00fc2c/logo-tripetchgroup.svg',
    alt: 'ติดต่อกลุ่มตรีเพชร',
    width: 207,
    height: 62,
  },
};

export const MENU_CV_ISUZU_HOTLINE: IMenu = {
  label: '',
  url: 'tel:021180777',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt33f0eff0eb661808/621dd2525662980e56b3b7c3/logo-isuzu-hotline.svg',
    alt: 'ISUZU HOTLINE สายด่วน',
    width: 200,
    height: 76,
  },
};

export const MENU_CV_CONTACT: IMenu = {
  label: 'ช่องทางการติดต่อ',
  url: '',
  submenu: [MENU_CV_TRI_PETCH_GROUP, MENU_CV_ISUZU_HOTLINE],
};

// CV LIFE CYCLE SOLUTION
export const MENU_CV_SERVICE_CENTER: IMenu = {
  label: 'โชว์รูม',
  url: '/lcs/service-center',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6e760c7be8867383/65e876cded37f6be27b108b1/showroom.jpg',
    alt: 'service center',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_ILSC: IMenu = {
  label: 'ศูนย์อีซูซุเพื่อสนับสนุน<span>โลจิสติกส์</span>',
  url: '/lcs/ilsc',
};

export const MENU_CV_TELETEC: IMenu = {
  label: 'เทเลเทค',
  url: '/lcs/teletec',
};

export const MENU_CV_RBSC: IMenu = {
  label: 'หน่วยงานสนับสนุนด้านตัวถัง',
  url: '/lcs/rbsc',
};

export const MENU_CV_TRUCK_SERVICE_LINE: IMenu = {
  label: 'Isuzu Truck Service Line',
  url: '/lcs/truck-serviceline',
};

export const MENU_CV_ISUZU_LEASING: IMenu = {
  label: 'ตรีเพชรลิสซิ่ง',
  url: '/lcs/isuzuleasing',
};

export const MENU_CV_TRUCK_2_HAND: IMenu = {
  label: 'Truck2Hand',
  url: '/lcs/truck2hand',
};

export const MENU_CV_SERVICE_PARTS: IMenu = {
  label: 'อะไหล่แท้ตรีเพชร',
  url: '/lcs/parts',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt83717f033a9c6c5e/61f2111da4c49a275282306f/3-parts.jpg',
    alt: 'isuzu parts',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_SERVICE_ISUZU_CARE: IMenu = {
  label: 'แพคเกจอีซูซุแคร์',
  url: '/lcs/isuzu-care',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaac647a706612632/62217e302e34b63e0774467e/4-isuzuCare.jpg',
    alt: 'isuzu care',
    width: 1600,
    height: 1200,
  },
};

export const MENU_CV_LIFE_CYCLE_SOLUTIONS: IMenu = {
  label: 'Life Cycle Solutions',
  submenu: [
    {
      label: 'ซื้อรถบรรทุกอย่างคุ้มค่า',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt63050ce708b0969f/65f257ba5fa1c632434bc9d0/menu-group-1.jpg',
        alt: 'service center',
        width: 1600,
        height: 1200,
      },
      submenu: [MENU_CV_SERVICE_CENTER, MENU_CV_ISUZU_LEASING, MENU_CV_RBSC],
    },
    {
      label: 'ลดต้นทุนตลอดการใช้งาน',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt82deef749123a101/65f257ba64055240897af5cf/menu-group-2.jpg',
        alt: 'isuzu care',
        width: 1600,
        height: 1200,
      },
      submenu: [
        MENU_CV_ILSC,
        MENU_CV_TELETEC,
        MENU_CV_SERVICE_PARTS,
        MENU_CV_SERVICE_ISUZU_CARE,
      ],
    },
    {
      label: 'บริการมาตรฐานราคาประหยัด',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta26c07344ac1b988/65f257baa1e8152db4d5aa83/menu-group-3.jpg',
        alt: 'service center',
        width: 1600,
        height: 1200,
      },
      submenu: [
        MENU_CV_SERVICE_CENTER,
        MENU_CV_SERVICE_PARTS,
        MENU_CV_SERVICE_ISUZU_CARE,
        MENU_CV_TRUCK_SERVICE_LINE,
      ],
    },
    {
      label: 'ขายต่อง่ายได้ราคา',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt70853227a2188bdd/65f257ba1732153e73d2b015/menu-group-4.jpg',
        alt: 'service center',
        width: 1600,
        height: 1200,
      },
      submenu: [MENU_CV_TRUCK_2_HAND],
    },
  ],
};

// OTHER SERVICE
export const MENU_CV_OTHER_SERVICE_LEASING: IMenu = {
  label: 'อีซูซุลิสซิ่ง',
  url: 'https://www.isuzuleasing.com',
  target: '_blank',
  showpanel: true,
};

export const MENU_CV_OTHER_SERVICE_INSURANCE: IMenu = {
  label: 'ตรีเพชรอินชัวร์รันส์เซอร์วิส',
  url: 'https://www.tripetchinsurance.com',
  target: '_blank',
  showpanel: true,
};

export const MENU_CV_OTHER_SERVICE_TRUCK2HAND: IMenu = {
  label: 'Truck2Hand',
  url: 'https://www.truck2hand.com',
  target: '_blank',
  showpanel: true,
};

export const MENU_CV_OTHER_SERVICE: IMenu = {
  label: 'บริการอื่นๆ',
  url: '',
  submenu: [
    // MENU_CV_OTHER_SERVICE_LEASING,
    MENU_CV_OTHER_SERVICE_INSURANCE,
    // MENU_CV_OTHER_SERVICE_TRUCK2HAND,
  ],
  showpanel: true,
};

// PRIMARY NAV
export const MENU_CV_PRIMARY_NAV: IMenu[] = [
  MENU_CV_MODEL_HEADER,
  MENU_CV_SPECIAL_OFFER,
  MENU_CV_LIFE_CYCLE_SOLUTIONS,
  MENU_CV_SERVICE,
  MENU_CV_CONTENT_HUB_GROUP,
  MENU_CV_ABOUT,
  MENU_CV_OTHER_SERVICE_INSURANCE,
];

// COPYRIGHT
export const COPYRIGHT = `สงวนลิขสิทธิ์ &copy; 2024 บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด`;

// BUTTONS
export const BUTTON_CV_REGISTER_TELETEC: IButton = {
  label: 'ลงทะเบียนรับข้อมูล',
  url: '/register/teletec',
};

export const BUTTON_CV_REGISTER_MY_ISUZU: IButton = {
  label: 'เข้าสู่ระบบ my-ISUZU',
  url: 'https://my.isuzu-tis.com',
  target: '_blank',
};

export const BUTTON_CV_REGISTER_ISUZU_CARE: IButton = {
  label: 'สนใจแพ็กเกจอีซูซุแคร์',
  url: '/register/isuzu-care',
};

// LCS MENU
export const MENU_LCS_SERVICE_CENTER: LcsItemProps = {
  title: 'โชว์รูม',
  desc: 'ศูนย์บริการครอบคลุมทั่วประเทศ พร้อมทีม ที่ปรึกษาการขายมืออาชีพ',
  link: '/lcs/service-center',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltac6bafcc3407eb77/65ddaa593b4c4f47317ad28c/service-center.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_SERVICE_CENTER_2: LcsItemProps = {
  title: 'โชว์รูม',
  desc: 'ครอบคลุมทั่วประเทศพร้อมทีมช่างที่ผ่านการอบรมให้ทันต่อเทคโนโลยีอันล้ำสมัย',
  link: '/lcs/service-center',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltac6bafcc3407eb77/65ddaa593b4c4f47317ad28c/service-center.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_LEASING: LcsItemProps = {
  title: 'ตรีเพชรลีสซิ่ง',
  desc: 'บริการสินเชื่อรถบรรทุก แบบครบวงจร “ให้การซื้อรถเป็นเรื่องง่าย เงื่อนไขไม่ยุ่งยาก”',
  link: '/lcs/isuzuleasing',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf0b89258c3c2c943/65ddaadc747141f59b0a2b2e/leasing.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_RBSC: LcsItemProps = {
  title: 'หน่วยงานสนับสนุนด้านตัวถัง',
  desc: 'ที่ปรึกษาด้านการต่อตัวถัง และการดัดแปลง พิเศษ ฟรี !!',
  link: '/lcs/rbsc',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2bb79e0f9007fdd8/65ddab4d3e2d0e6ac4f70cc5/rbsc.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_ILSC: LcsItemProps = {
  title: 'ศูนย์อีซูซุเพื่อสนับสนุนโลจิสติกส์',
  desc: 'การอบรมขับขี่อย่างประหยัดและปลอดภัย ครอบคลุมทั้งผู้บริหาร และพนักงานขับรถ',
  link: '/lcs/ilsc',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltafbb82e194a4c3aa/65ddba5fd778b01671ad5501/ilsc.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_PARTS: LcsItemProps = {
  title: 'อะไหล่แท้ตรีเพชร',
  desc: 'อะไหล่คุณภาพ ราคาประหยัด ลดโอกาส ที่รถจะเสียหายเร็วกว่ากำหนด',
  link: '/lcs/parts',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt56575102fb5308f0/65ddac9a7c85269f90234105/parts.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_PARTS_2: LcsItemProps = {
  title: 'อะไหล่แท้ตรีเพชร',
  desc: 'อะไหล่คุณภาพสูงเทียบเท่ากับอะไหล่ที่ติดตั้งมากับตัวรถ ในราคาประหยัด',
  link: '/lcs/parts',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt56575102fb5308f0/65ddac9a7c85269f90234105/parts.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_TELETEC: LcsItemProps = {
  title: 'เทเลเทค',
  desc: 'GPS อัจฉริยะ ช่วยวิเคราะห์และพัฒนาพฤติกรรมการขับ ให้ขับประหยัดและปลอดภัย',
  link: '/lcs/teletec',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd14c21e8dd280d0e/65ddf3fed781fe7b03e7358f/teletec.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_ISUZU_CARE: LcsItemProps = {
  title: 'แพคเกจอีซูซุแคร์',
  desc: 'ตัดความกังวลทุกปัญหาการซ่อมบำรุง ควบคุมค่าซ่อมบำรุงอย่างมั่นใจ',
  link: '/lcs/isuzu-care',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt641d4be8e6dac728/65ddad453b4c4f71447ad29a/isuzu-care.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_ISUZU_CARE_2: LcsItemProps = {
  title: 'แพคเกจอีซูซุแคร์',
  desc: 'ดูแลรถเชิงป้องกัน ให้รถสมบูรณ์พร้อมใช้ ตลอดเวลา',
  link: '/lcs/isuzu-care',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt641d4be8e6dac728/65ddad453b4c4f71447ad29a/isuzu-care.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_TRUCK_SERVICE_LINE: LcsItemProps = {
  title: 'Isuzu Truck Service Line',
  desc: 'ให้คําปรึกษาเบื้องต้นทางโทรศัพท์ 24 ชม.',
  link: '/lcs/truck-serviceline',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9ae589db4bca6bca/65ddf5302c8bef80d3621512/truck-service-line.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};

export const MENU_LCS_TRUCK_2_HAND: LcsItemProps = {
  title: 'Truck2Hand',
  desc: 'ผู้นําด้านตลาดออนไลน์สําหรับรถบรรทุก มือสอง “ซื้อง่าย ขายไว มั่นใจเรา”',
  link: '/lcs/truck2hand',
  logo: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte546ef26a7802384/65ddf78e6e7edb4093b4f4b9/truck-2-hand.jpg',
    dimension: {
      width: 80,
      height: 80,
    },
  },
};
